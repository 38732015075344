import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./shared/Navbar";
import Footer from "./shared/Footer";
import ApplyNowForm from "./shared/ApplyNowForm";
import Contract from "./pages/Contract";

const Home = React.lazy(() => import("./pages/Home"));
const About = React.lazy(() => import("./pages/AboutPage"));

function RouteConfig() {
  const [active, setActive] = useState(null);
  const pathName = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathName]);
  return (
    <>
      <Navbar setActive={setActive} active={active} />
      <Routes>
        <Route path="/" exact element={<Home setActive={setActive} />} />
        <Route
          path="/about-us"
          exact
          element={<About setActive={setActive} />}
        />
        <Route path="/apply-now" element={<ApplyNowForm />} />
        <Route path="/contractors" element={<Contract />} />
      </Routes>
      <Footer />
    </>
  );
}

export default RouteConfig;

import React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '../shared/Button';
import { useState } from 'react';
import { contractApi } from '../api/api';

function Contract() {
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    name: '',
    email: '',
    company_name: '',
    company_registration_number: '',
    message: '',
    site: 'security'
  };
  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    company_name: Yup.string().required('Company name is required'),
    company_registration_number: Yup.string().required('Company registration number is required'),
    message: Yup.string()
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        contractApi(values, setIsLoading).then(() => {
          resetForm();
        });
      }}
    >
      {({ handleSubmit }) => (
        <div className='mt-[90px] pt-4 bg-gray-100 pb-5'>
          <div className='w-full px-4 container mx-auto'>
            <h1 className='section-title !text-primary py-4 w-full text-center'>Contractor Application Form</h1>
            <p className='section-text pb-4 w-full md:w-[90%] lg:w-[70%] mx-auto text-center'>
              MH7 Services Ltd follows BS 7858 standards, requiring all contractors to complete a screening and
              licensing process for compliance and security.
            </p>
            <div className='bg-white shadow-md rounded-xl h-auto w-full md:w-[90%] xl:w-[80%] p-4 mt-6 mx-auto'>
              <Form onSubmit={handleSubmit}>
                <div className='grid grid-cols-12 gap-4'>
                  <div className='form-group'>
                    <label className='form-label'>Name*</label>
                    <Field name='name' type='text' placeholder='Your name' className='input input-bordered w-full' />
                    <ErrorMessage name='name' component='div' className='text-red-500' />
                  </div>

                  <div className='form-group'>
                    <label className='form-label'>Email*</label>
                    <Field name='email' type='email' placeholder='Your email' className='input input-bordered w-full' />
                    <ErrorMessage name='email' component='div' className='text-red-500' />
                  </div>

                  <div className='form-group'>
                    <label className='form-label'>Company Name*</label>
                    <Field
                      name='company_name'
                      type='text'
                      placeholder='Company name'
                      className='input input-bordered w-full'
                    />
                    <ErrorMessage name='company_name' component='div' className='text-red-500' />
                  </div>

                  <div className='form-group'>
                    <label className='form-label'>Company Registration Number*</label>
                    <Field
                      name='company_registration_number'
                      type='text'
                      placeholder='Registration number'
                      className='input input-bordered w-full'
                    />
                    <ErrorMessage name='company_registration_number' component='div' className='text-red-500' />
                  </div>

                  <div className='form-group md:!col-span-12 '>
                    <label className='form-label'>Message</label>
                    <Field
                      name='message'
                      as='textarea'
                      placeholder='Your message'
                      className='input input-bordered w-full min-h-[120px]'
                    />
                    <ErrorMessage name='message' component='div' className='text-red-500' />
                  </div>
                </div>
              </Form>
              <div className='my-4'>
                <Button
                  type='submit'
                  text={isLoading ? <span className='loading loading-spinner loading-sm'></span> : 'Submit'}
                  disabled={isLoading}
                  variant='filled'
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}

export default Contract;

import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import data from "../data.json";
import Button from "./Button";

export const scrollToFRef = (ref) => {
  ref.current?.scrollIntoView({ behavior: "smooth" });
};

const Navbar = ({ setActive, active }) => {
  const [navLinks, setNavlinks] = useState([]);
  const { homeNavLinks, aboutNavLinks } = data;

  const location = useLocation();

  useEffect(() => {
    if (location?.pathname == "/") {
      setNavlinks(homeNavLinks);
    } else {
      setNavlinks(aboutNavLinks);
    }
  }, [location?.pathname]);

  const navigate = useNavigate();
  const handleOpenForm = () => {
    navigate("/apply-now");
  };
  return (
    <div className="bg-white shadow w-full z-[2000] h-[90px] fixed top-0 flex items-center ">
      <div className="navbar bg-transparent container mx-auto">
        <div className="navbar-start">
          <div className="dropdown">
            <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </div>
            <ul
              tabIndex={0}
              className="menu menu-sm dropdown-content bg-white  rounded-box mt-3 w-52 p-2 shadow z-[99]"
            >
              {location?.pathname == "/"
                ? navLinks?.map((data, index) =>
                    data?.type == "external" ? (
                      <NavLink
                        onClick={() => {
                          setActive(data?.link);
                        }}
                        to={data?.link}
                        className={`nav-link my-1 ${
                          active === data?.link ? "active-nav" : ""
                        }`}
                        key={index}
                      >
                        {data?.name}
                      </NavLink>
                    ) : (
                      <a
                        onClick={() => {
                          setActive(data?.link);
                        }}
                        href={`#${data?.link}`}
                        className={`nav-link my-1 ${
                          active === data?.link ? "active-nav" : ""
                        }`}
                        key={index}
                      >
                        {data?.name}
                      </a>
                    )
                  )
                : navLinks?.map((data, index) => (
                    <NavLink
                      to={data?.link}
                      className={`nav-link my-1`}
                      key={index}
                    >
                      {data?.name}
                    </NavLink>
                  ))}
              <div className="flex flex-col md:hidden mt-2">
                <Button text={"Apply Now"} variant={"filled"} />
                <Button
                  text={"Contractors"}
                  className="mt-3"
                  variant={"outlined"}
                />
              </div>
            </ul>
          </div>
          <NavLink
            to="/"
            className="nav-header cursor-pointer h-[90px] flex flex-col items-center justify-center"
          >
            <p className="mb-0 text-center text-[35px] font-[700]">MH7</p>
            <span className="text-blue text-[14px] font-[500] whitespace-nowrap">
              Security Services
            </span>
          </NavLink>
        </div>
        <div className="navbar-center hidden lg:flex">
          <ul className="menu menu-horizontal px-1">
            {location?.pathname == "/"
              ? navLinks?.map((data, index) =>
                  data?.type == "external" ? (
                    <NavLink
                      onClick={() => {
                        setActive(data?.link);
                      }}
                      to={data?.link}
                      className={`nav-link my-3 ${
                        active === data?.link ? "active-nav" : ""
                      }`}
                      key={index}
                    >
                      {data?.name}
                    </NavLink>
                  ) : (
                    <a
                      onClick={() => {
                        setActive(data?.link);
                      }}
                      href={`#${data?.link}`}
                      className={`nav-link my-3 ${
                        active === data?.link ? "active-nav" : ""
                      }`}
                      key={index}
                    >
                      {data?.name}
                    </a>
                  )
                )
              : navLinks?.map((data, index) => (
                  <NavLink
                    to={data?.link}
                    className={`nav-link my-3`}
                    key={index}
                  >
                    {data?.name}
                  </NavLink>
                ))}
          </ul>
        </div>
        <div className="navbar-end hidden md:flex">
          <Button
            text={"Apply Now"}
            variant={"filled"}
            onClick={handleOpenForm}
          />
          <Button
            text={"Contractors"}
            className="ms-3"
            variant={"outlined"}
            onClick={() => navigate("/contractors")}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;

import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import RouteConfig from "./RouteConfig";
import Loading from "./shared/Loading";

function App() {
  return (
    <>
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <RouteConfig />
        </BrowserRouter>
      </Suspense>
    </>
  );
}

export default App;

import React from "react";
import { motion } from "framer-motion";
import { cn } from "../utils/util";

const Button = ({
  text,
  onClick,
  type = "button",
  className,
  variant,
  disabled = false,
}) => {
  return (
    <motion.button
      whileTap={{ scale: 0.95 }}
      type={type}
      className={cn(
        "custom-button transition-colors duration-700 ease-in-out",
        variant === "filled"
          ? "bg-primary text-white hover:bg-white hover:text-primary border border-primary"
          : "bg-white text-primary border border-primary hover:bg-primary hover:text-white",
        className
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </motion.button>
  );
};

export default Button;
